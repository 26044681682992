/* eslint-disable jsx-a11y/control-has-associated-label */
// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2024.06.03 || YYYY/MM/DD

import React from 'react';
import { string } from 'prop-types';

import { Link } from 'react-router-dom';
import InterfaceIcon54Origins from './InterfaceIcon54Origins';

function InterfaceCard54Origins({
  title,
  description,
  icon,
  iconColor,
  link,
}) {
  const openInNewWindow = () => window.open(link, title, `width=${window.screen.width},height=${window.screen.height}`);

  return (
    <div className="interface__card">
      <Link className="interface__content" to={link} target="_blank" rel="noopener noreferrer">
        <div className="interface__header">
          <div className="interface__icon">
            <InterfaceIcon54Origins
              color={iconColor}
              iconName={icon}
              size={1}
            />
          </div>
          <h6 className="interface__title">
            {title}
          </h6>
        </div>
        {description && (<p className="interface__description">{description}</p>)}
        <span className="interface__link">{link}</span>
      </Link>
      <div className="interface__actions">
        <button
          type="button"
          className="interface__button interface__button--link"
          onClick={openInNewWindow}
        >
          <span>In new page</span>
        </button>
      </div>
    </div>
  );
}

InterfaceCard54Origins.propTypes = {
  title: string,
  description: string,
  icon: string,
  iconColor: string,
  link: string.isRequired,
};

export default InterfaceCard54Origins;
