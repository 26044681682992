import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button, Popconfirm,
  Tag,
} from 'antd';
import { isNull } from 'lodash';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TableTitleWrapper from '../../components/TableTitleWrapper';
import AntTable from '../../components/AntTable';
import AntDesignSearchBox54origins from '../../54origins/components/AntDesignSearchBox54origins';
import InternalOrExternalTag from './InternalOrExternalTag';

import { getLocale } from '../../locale/selectors';
import { capitalize } from '../../mainUtils';
import { getNameForActor } from '../../54origins/utils54origins';

import useAuthActorsRequests from '../hooks/useAuthActorsRequests';
import BaseButton from '../../_ui/BaseButton/BaseButton';
import BaseTag from '../../_ui/BaseTag/BaseTag';

function FormatName({
  popConfirmVisible,
  onConfirm,
  onCancel,
  row,
}) {
  const { t } = useTranslation();
  const { actor_type, uinfo, uuid } = row || {};

  const getName = () => {
    switch (actor_type) {
      case 'service':
        return getNameForActor(row);
      case 'user':
      case 'classic_user':
        const { isRoot, isAdmin } = uinfo;
        const tagName = isRoot ? 'root' : 'default';
        return (
          <>
            <span>
              {getNameForActor(row)}
            </span>
            {(isRoot || isAdmin) && (
              <BaseTag className={`tag-${isRoot ? 'purple' : 'gray'} ml-2`}>
                {t(`auth.headers.${tagName}`, tagName).toUpperCase()}
              </BaseTag>
            )}
          </>
        );
      case 'group':
        return getNameForActor(row);
      default:
        return 'actor type not found';
    }
  };

  return (
    <div className="flex items-center">
      <Popconfirm
        open={popConfirmVisible === uuid}
        placement="topLeft"
        title="You are trying to add an external user, are you sure?"
        onConfirm={onConfirm}
        onCancel={onCancel}
        okText="Yes"
        cancelText="No"
        color="yellow"
      >
        {getName()}
      </Popconfirm>
    </div>
  );
}

function ModalListOfActors({
  doNotMakeRequest = [],
  addActorsAfterRequest = [],
  dataForActorsRequest,
  removeActorsAfterRequest = [],
  getSelectedActors,
  listOfActorsActionBtnLabel = 'add to actor',
}) {
  const { requestGetListOfActorsOnAuth } = useAuthActorsRequests();

  const { t } = useTranslation();

  const locale = useSelector(getLocale) || 'en';

  const initState = {
    tableData: [],
    currentPage: 1,
    pageLimit: 10,
    actorsCount: 0,
    initSearch: false,
    popConfirmVisible: '',
    externalActor: '',
    loading: false,
    orderRules: {
      order_by_column: 'created',
      order_by_rule: 'desc',
    },
  };
  const [state, setState] = useState(initState);

  const {
    tableData,
    currentPage,
    pageLimit,
    actorsCount,
    searchValue,
    initSearch,
    loading,
    orderRules,
    popConfirmVisible,
    externalActor,
  } = state;

  const changeState = (params) => setState((prev) => ({ ...prev, ...params }));

  const pageCountLocalActors = Math.ceil(addActorsAfterRequest.length / pageLimit);

  const changeSort = (param, sortOrder) => {
    changeState({
      orderRules: {
        order_by_column: param,
        order_by_rule: sortOrder === 'ascend' ? 'asc' : 'desc',
      },
    });
  };

  const onChangeActorsPagination = (currentPage) => changeState({ currentPage });

  const onShowSizeChange = (current, size) => changeState({ pageLimit: size });

  const onSearch = (value) => changeState({ searchValue: value });

  const getActorsAndCount = async () => {
    changeState({ loading: true });

    const customOffset = pageLimit * (currentPage - 1 - pageCountLocalActors);

    const finalData = {
      ...orderRules,
      ...dataForActorsRequest,
      limit: pageLimit,
      offset: customOffset,
    };

    if (searchValue) {
      finalData.search_data = {
        value: searchValue,
        ignore_case: true,
        fields:
            {
              base: ['uuid'],
              uinfo: ['first_name', 'last_name', 'group_name', 'service_name'],
            },
      };
    }

    // console.log('finalData', finalData);

    const actorsRequest = await requestGetListOfActorsOnAuth(finalData, []);

    // console.log('actorsRequest', actorsRequest);

    const { actors = [], total = 0 } = actorsRequest || {};

    let modifiedActors = isNull(actors) ? [] : actors;
    modifiedActors = modifiedActors.filter((item) => !removeActorsAfterRequest.includes(item.uuid));

    changeState({
      actorsCount: total,
      tableData: modifiedActors,
      loading: false,
    });
  };

  const onRow = (record) => ({
    onClick: () => {
      // onSelectActors(uuid, record)
      const {
        uinfo: {
          internal_user: internal = false,
        },
        uuid,
      } = record || {};

      if (internal) {
        getSelectedActors([record]);
      } else {
        changeState({
          popConfirmVisible: uuid,
          externalActor: record,
        });
      }
    },
  });

  const onConfirm = () => {
    getSelectedActors([externalActor]);
    changeState({
      popConfirmVisible: '',
      externalActor: '',
    });
  };

  const onCancel = (e) => {
    e.stopPropagation();

    changeState({
      popConfirmVisible: '',
      externalActor: '',
    });
  };

  const formatActorType = (cell) => (
    <BaseTag className={`tag-${cell}`}>
      {capitalize(t(`auth.headers.${cell}`, cell))}
    </BaseTag>
  );

  const formatActions = (cell, row) => (
    <div>
      <BaseButton
        className="button-access-outlined px-2"
        size="small"
        onClick={() => getSelectedActors([row])}
      >
        {listOfActorsActionBtnLabel}
      </BaseButton>
    </div>
  );

  const columns = useMemo(() => [
    {
      dataIndex: 'uuid',
      // colSpan: 1,
      key: 'actions',
      className: 'p-2 pr-4',
      title: '',
      render: formatActions,
    },
    {
      dataIndex: 'name',
      key: 'name',
      className: 'p-2',
      title: (
        <AntDesignSearchBox54origins
          onSearch={onSearch}
          placeholder="input search name"
        />
      ),
      sorter: (a, b, sortOrder) => changeSort('title', sortOrder),
      render: (cell, row) => (
        <FormatName
          cell={cell}
          row={row}
          popConfirmVisible={popConfirmVisible}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      ),
      // shouldCellUpdate: (record, prevRecord) => record?.uuid === popConfirmVisible,
    },
    {
      dataIndex: 'actor_type',
      key: 'actor_type',
      title: (
        <TableTitleWrapper minWidth={40}>
          {capitalize(t('auth.headers.type', 'type'))}
        </TableTitleWrapper>
      ),
      sorter: (a, b, sortOrder) => changeSort('actor_type', sortOrder),
      className: 'p-2',
      align: 'left',
      render: formatActorType,
    },
    {
      dataIndex: ['uinfo', 'internal_user'],
      key: 'internal_user',
      title: (
        <TableTitleWrapper minWidth={40}>
          {capitalize(t('type', 'subtype'))}
        </TableTitleWrapper>
      ),
      sorter: (a, b, sortOrder) => changeSort('internal_user', sortOrder),
      className: 'p-2',
      align: 'left',
      render: (cell) => <InternalOrExternalTag internal={cell} />,
    },
    {
      dataIndex: 'created',
      key: 'created',
      title: (
        <TableTitleWrapper minWidth={120}>
          {capitalize(t('auth.headers.created', 'created'))}
        </TableTitleWrapper>
      ),
      className: 'p-2',
      textWrap: 'word-break',
      sorter: (a, b, sortOrder) => changeSort('created', sortOrder),
      render: (cell) => capitalize(dayjs(cell)
        .local()
        .locale(locale)
        .format('MMM D, h:mm A')),
      defaultSortOrder: 'descend',
    },
    {
      dataIndex: ['uinfo', 'date_updated'],
      key: 'modify',
      title: (
        <TableTitleWrapper minWidth={120}>
          {capitalize(t('auth.headers.modify', 'modify'))}
        </TableTitleWrapper>
      ),
      className: 'p-2',
      textWrap: 'word-break',
      // sorter: (a, b, sortOrder) => changeSort('created', sortOrder),
      render: (cell) => cell && capitalize(dayjs(cell)
        .local()
        .locale(locale)
        .format('MMM D, h:mm A')),
      // defaultSortOrder: 'descend',
    },
  ], [tableData]);

  const rowSelection = {
    columnWidth: 40,
    selectedRowKeys: [],
  };

  const calculateTotal = () => actorsCount + (pageCountLocalActors * pageLimit);

  const getActorsIfEmpty = async () => {
    onChangeActorsPagination(Math.abs(currentPage - 1));
    await getActorsAndCount();
  };

  useEffect(() => {
    if (!doNotMakeRequest.includes('getActors')
    // || dataForActorsRequest.uuid.length === 0
    ) {
      getActorsAndCount();

      if (searchValue && !initSearch) {
        // console.log('FIRST SEARCH')
        changeState({
          currentPage: 1,
          initSearch: true,
        });
      } else if (!searchValue) {
        // console.log('EMPTY SEARCH')
        changeState({ initSearch: false });
      }
    }
  }, [
    currentPage,
    pageLimit,
    searchValue,
    JSON.stringify(orderRules),
    JSON.stringify(dataForActorsRequest),
  ]);

  useEffect(() => {
    if (tableData.length === 0 && currentPage !== 1) {
      getActorsIfEmpty();
    }
  }, [tableData.length]);

  // console.log('tableData', tableData);

  return (
    <div>
      <AntTable
        columns={columns}
        current={currentPage}
        data={tableData}
        disablePagination={false}
        loading={loading}
        onChangePagination={onChangeActorsPagination}
        onRow={onRow}
        onShowSizeChange={onShowSizeChange}
        paginationPageSize={pageLimit}
        paginationSize="default"
        rowSelection={rowSelection}
        total={calculateTotal()}
      />
    </div>
  );
}

export default ModalListOfActors;

ModalListOfActors.propTypes = {
  addActorsAfterRequest: PropTypes.array,
  dataForActorsRequest: PropTypes.any,
  doNotMakeRequest: PropTypes.array,
  getSelectedActors: PropTypes.func,
  listOfActorsActionBtnLabel: PropTypes.string,
  removeActorsAfterRequest: PropTypes.array,
};

FormatName.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  popConfirmVisible: PropTypes.bool,
  row: PropTypes.any,
};
