import React from 'react';
import PropTypes from 'prop-types';
import { mdiMenu, mdiMenuOpen } from '@mdi/js';
import Icon from '@mdi/react';
import './HeaderSidebar.scss';

function HeaderSidebar({
  name,
  link,
  minWidth,
  sidebarWidth,
  menuOpen,
}) {
  return (
    <div
      style={{
        minWidth: `${minWidth}px`,
        width: sidebarWidth ? menuOpen ? '0' : `${sidebarWidth}px` : `${minWidth}px`,
      }}
      className="flex items-center justify-between header-logo-block"
    >
      <a
        href={link}
        className="flex items-center h-full"
      >
        {name}
      </a>
    </div>
  );
}

HeaderSidebar.propTypes = {
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  minWidth: PropTypes.number,
  sidebarWidth: PropTypes.number,
  menuOpen: PropTypes.bool,
};

export default HeaderSidebar;
