import React, { memo } from 'react';
import {
  Col, Flex, Row, Space,
} from 'antd';
import { Link } from 'react-router-dom';

import './Header.scss';

import { UI54Button, UI54Header, UI54Icon } from '@agpl/ui54kit';
import { mdiMenuClose, mdiMenuOpen } from '@mdi/js';
import LocaleSwitcher from '../LocaleSwitcher';
import ProfileMenu from '../ProfileMenu';
import MasqueradingTurnOffButton from '../MasqueradingTurnOffButton';
import InterfacesListModal54Origins
  from '../../54origins/components/publicInterface54Origins/InterfacesListModal54Origins';

import { urlAPI } from '../../api/api';
import HeaderSidebar from '../HeaderSidebar/HeaderSidebar';

function Header({
  toggleMenu,
  menuOpen,
  fixed,
  headerHeight,
  className,
  sidebarWidth,
}) {
  return (
    <UI54Header fixed={fixed} height={headerHeight} bordered>
      <Flex align="center" className="h-full">
        <HeaderSidebar
          link="/"
          name="Auth service"
          minWidth={170}
          sidebarWidth={sidebarWidth}
          menuOpen={menuOpen}
        />

        <UI54Button
          id="menuToggleButton"
          icon={(
            <UI54Icon
              path={menuOpen ? mdiMenuClose : mdiMenuOpen}
              size={1.2}
            />
          )}
          onClick={toggleMenu}
          style={{
            width: `${headerHeight}px`,
          }}
          className="shrink-0 h-full"
        />

        <Space size={0} className="ml-auto h-full header-space shrink-0">
          <MasqueradingTurnOffButton />

          <InterfacesListModal54Origins
            url={urlAPI}
            sessionTokenName="Auth"
            className="h-full"
          />

          <LocaleSwitcher />

          <ProfileMenu />
        </Space>
      </Flex>
    </UI54Header>
  );
}

export default memo(Header);
