import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { mdiGhostOutline, mdiTrashCanOutline } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Button,
  Tag,
  Tooltip,
  Checkbox,
} from 'antd';
import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { UI54Table } from '@agpl/ui54kit';
import DeleteButton from '../../components/DeleteButton';
import TableTitleWrapper from '../../components/TableTitleWrapper';
import AntTableWithPagination from '../../components/AntTableWithPagination';
import ActorsModalBtn from './ActorsModalBtn';
import InternalOrExternalTag from './InternalOrExternalTag';
import RobotActorTag from './RobotActorTag';

import { getLocale } from '../../locale/selectors';
import { capitalize } from '../../mainUtils';
import {
  getAllGroups, getDefaultBanGroupUUID,
  getDefaultGroupsMap,
} from '../selectors';
import { getRegisteredOnServicesDataMap } from '../reducers/actorsMapReducer';
import { getNameForActor } from '../../54origins/utils54origins';
import { getMeIsMajorGroup, getMeIsRootOrAdmin } from '../../profile/selectors';
import useURLParams from '../../hooks/useURLParams';
import BaseIcon from '../../_ui/BaseIcon/BaseIcon';
import BaseButton from '../../_ui/BaseButton/BaseButton';
import BaseTag from '../../_ui/BaseTag/BaseTag';

function ActorsList({
  actorUUID,
  afterDeleteActor,
  filterByTags,
  getActorsAndCount,
  onSelectActors,
  selectActor,
  selectedActors,
  setMasqueradingUser,
  switchView,
  tableData,
  total,
  withCheckBox = true,
  fetching = false,
  onSuccessUpdateCallBack,
  getOrderCallBack,
  onChangePaginationCallback,
}) {
  dayjs.extend(utc);

  const { t } = useTranslation();

  const { getURLParams, setSearchParams, routeNavigateAndClearParams } = useURLParams();

  const meIsRootOrAdmin = useSelector(getMeIsRootOrAdmin);
  const myMajorGroup = useSelector(getMeIsMajorGroup);
  const allGroups = useSelector(getAllGroups);
  const defaultGroupsMap = useSelector(getDefaultGroupsMap);
  const locale = useSelector(getLocale) || 'en';
  const registeredOnServicesData = useSelector(getRegisteredOnServicesDataMap);
  const defaultGroupUUID = useSelector(getDefaultBanGroupUUID);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);
  const offset = pageLimit * (currentPage - 1);

  // console.log('RENDER ActorsList');

  const [orderRules, changeOrderRules] = useState({
    order_by_column: 'created',
    order_by_rule: 'desc',
  });

  const {
    order_by_rule: orderRule,
    order_by_column: orderColumn,
  } = orderRules || {};

  const [newCurrentPage, setNewCurrentPage] = useState(null);

  const { page, limit } = getURLParams();

  const changeSort = useCallback((param, sortOrder) => {
    // console.log('changeSort changeSort');

    changeOrderRules({
      order_by_column: param,
      order_by_rule: sortOrder === 'ascend' ? 'asc' : 'desc',
    });

    // setSearchParams({
    //   order: `${sortOrder === 'ascend' ? 'a' : 'd'}-${param}`,
    // });
  }, []);

  const titleDeleteCheckBox = (
    <Checkbox
      onClick={(e) => e.stopPropagation()}
      indeterminate={selectedActors.size !== 0 && selectedActors.size !== tableData.length}
      checked={selectedActors.size === tableData.length}
      onChange={() => onSelectActors('all')}
    />
  );

  const formatDeleteCheckBox = (cell) => (
    <Checkbox
      onClick={(e) => e.stopPropagation()}
      onChange={() => onSelectActors(cell)}
      checked={selectedActors.has(cell)}
    />
  );

  const formatName = (cell, row) => {
    const isRoot = row.root;
    const isDefault = row.default;
    const tagName = isRoot ? 'root' : 'default';

    let tagNames = [];

    if (row.groups && row.groups.length > 0 && allGroups.length > 0) {
      tagNames = row.groups
        .map((uuid) => _.get(defaultGroupsMap.get(uuid), 'uinfo.group_name'))
        .filter((item) => ['BAN', 'ADMIN'].includes(item));
    }

    // console.log('ROW ROW', row)

    return (
      <div className="flex items-center break-word">
        {cell}
        {(isRoot || isDefault) && (
          <BaseTag className={`tag-${isRoot ? 'purple' : 'gray'} ml-2`}>
            {t(`auth.headers.${tagName}`, tagName).toUpperCase()}
          </BaseTag>
        )}
        {tagNames.map((tag) => (
          <BaseTag
            key={`default_tag_${row.uuid}_${tag}`}
            className={`tag-${tag === 'BAN' ? 'red' : 'volcano'} ml-2`}
          >
            {t(`auth.headers.${tag.toLowerCase()}`, tag)}
          </BaseTag>
        ))}
        <RobotActorTag isRobot={row?.isRobot} />
      </div>
    );
  };

  const formatType = (cell) => (
    <BaseTag className={`tag-${cell}`}>
      {capitalize(t(`auth.headers.${cell}`, cell))}
    </BaseTag>
  );

  const formatGroup = (cell, row) => {
    const { type, uuid } = row;

    return (
      <ActorsModalBtn
        actorUUID={uuid}
        actorType={type}
        onSaveCallBack={onSuccessUpdateCallBack}
      />
    );
  };

  const formatWeight = (cell, row) => (row.type === 'group' ? (
    <Tooltip title={cell}>
      <span
        className="inline-block truncate"
        style={{ maxWidth: 75 }}
      >
        {cell > 4294967297 ? 'MAX' : cell}
      </span>
    </Tooltip>
  ) : null);

  const formatActions = (uuid, row) => {
    const weight = +_.get(row, 'weight');
    const disabled = weight === 0 || weight > 4294967297;

    const actorType = _.get(row, 'type');
    const actorIsUser = actorType === 'user' || actorType === 'classic_user';
    const masqueradingDisabled = !actorIsUser || (myMajorGroup === 'ADMIN' && row.root);

    return (
      <div className="flex items-center h-full">
        <DeleteButton
          targetType="actor"
          onSuccess={afterDeleteActor}
          uuid={uuid}
          disabled={disabled}
        >
          <BaseButton
            className="button-danger-outlined px-2"
            size="small"
            disabled={disabled}
          >
            <BaseIcon
              path={mdiTrashCanOutline}
              size={1}
            />
          </BaseButton>
        </DeleteButton>
        {meIsRootOrAdmin && (
          <Tooltip
            title={capitalize(t(
              'auth.headers.masquerading',
              'masquerading',
            ))}
          >
            <BaseButton
              className="button-warning-outlined px-2 ml-2"
              size="small"
              disabled={masqueradingDisabled}
              onClick={(e) => {
                e.stopPropagation();
                setMasqueradingUser(uuid);
              }}
              onDoubleClick={(e) => e.stopPropagation()}
            >
              <span style={{ padding: '0 1px' }}>
                M
              </span>
              {/* <BaseIcon path={mdiGhostOutline} size={1} /> */}
            </BaseButton>
          </Tooltip>
        )}
      </div>
    );
  };

  const rowSelection = {
    columnWidth: 40,
    selectedRowKeys: [actorUUID],
  };

  const columns = useMemo(() => (
    [
      (withCheckBox ? {
        dataIndex: 'uuid',
        key: 'delete_checkbox',
        className: 'p-2 pl-4',
        title: titleDeleteCheckBox,
        render: formatDeleteCheckBox,
      } : {}),
      {
        dataIndex: 'name',
        key: 'name',
        className: 'p-2',
        title: (
          <TableTitleWrapper minWidth="100%">
            {capitalize(t('auth.headers.title', 'title'))}
          </TableTitleWrapper>
        ),
        // sorter: (a, b) => _.get(a, 'name', '').localeCompare(_.get(b, 'name', '')),
        sorter: (a, b, sortOrder) => changeSort('title', sortOrder),
        render: formatName,
      },
      {
        dataIndex: 'type',
        key: 'type',
        title: (
          <TableTitleWrapper minWidth={40}>
            {capitalize(t('auth.headers.type', 'type'))}
          </TableTitleWrapper>
        ),
        // sorter: (a, b) => _.get(a, 'type', '').localeCompare(_.get(b, 'type', '')),
        sorter: (a, b, sortOrder) => changeSort('actor_type', sortOrder),
        className: 'p-2',
        align: 'left',
        render: formatType,
      },
      {
        dataIndex: 'groups',
        key: 'groups',
        title: (
          <TableTitleWrapper minWidth={50}>
            {capitalize(t('auth.headers.groups', 'groups'))}
          </TableTitleWrapper>
        ),
        className: 'p-2',
        // sorter: (a, b) => _.get(a, 'groups.length', 0) - _.get(b, 'groups.length', 0),
        render: formatGroup,
      },
      {
        dataIndex: 'weight',
        key: 'weight',
        title: (
          <TableTitleWrapper minWidth={60}>
            {capitalize(t('auth.headers.weight', 'weight'))}
          </TableTitleWrapper>
        ),
        className: 'p-2',
        // sorter: (a, b) => +_.get(a, 'weight', 0) - +_.get(b, 'weight', 0),
        sorter: (a, b, sortOrder) => changeSort('weight', sortOrder),
        render: formatWeight,

      },
      {
        dataIndex: 'registeredOnServiceUUID',
        key: 'registered_on_service_uuid',
        title: (
          <TableTitleWrapper minWidth={100}>
            {capitalize(t('auth.headers.weight', 'registered on'))}
          </TableTitleWrapper>
        ),
        className: 'p-2',
        sorter: (a, b, sortOrder) => changeSort('registered_on_service_uuid', sortOrder),
        render: (cell) => (<BaseTag color="blue">{cell ? getNameForActor(registeredOnServicesData.get(cell)) : 'not found'}</BaseTag>),
      },
      {
        dataIndex: 'internal',
        key: 'internal',
        title: (
          <TableTitleWrapper minWidth={60}>
            {capitalize(t('auth.headers.weight', 'internal'))}
          </TableTitleWrapper>
        ),
        className: 'p-2',
        sorter: (a, b, sortOrder) => changeSort('internal_user', sortOrder),
        render: (cell) => <InternalOrExternalTag internal={cell} />,
      },
      {
        dataIndex: 'created',
        key: 'created',
        title: (
          <TableTitleWrapper minWidth={120}>
            {capitalize(t('auth.headers.created', 'created'))}
          </TableTitleWrapper>
        ),
        className: 'p-2',
        textWrap: 'word-break',
        // sorter: (a, b) => {
        //   const dateA = new Date(a.created).getTime();
        //   const dateB = new Date(b.created).getTime();
        //
        //   return dateB - dateA;
        // },
        sorter: (a, b, sortOrder) => changeSort('created', sortOrder),
        render: (cell) => capitalize(dayjs.utc(cell)
          .local()
          .locale(locale)
          .format('MMM D, h:mm A')),
        defaultSortOrder: 'descend',
      },
      {
        dataIndex: 'uuid',
        key: 'actions',
        className: 'p-2 pr-4',
        title: '',
        render: formatActions,
      },
    ]
  ), [selectedActors, tableData]);

  const onRow = (row) => {
    const { uuid } = row;

    return {
      onClick: (e) => {
        e.stopPropagation();
        if (actorUUID === uuid) {
          switchView?.();
        } else {
          selectActor('info', uuid);
        }
      },
      onDoubleClick: (e) => {
        e.stopPropagation();

        routeNavigateAndClearParams('/admin/actors/permissions', {
          uuid,
        });
      },
    };
  };

  const savePagination = (data) => {
    // console.log('savePagination');
    const { pageLimit, currentPage } = data;

    setSearchParams({
      page: currentPage,
      limit: pageLimit,
    });
  };

  const onChangePagination = (page) => {
    setCurrentPage(page);
    if (onChangePaginationCallback) {
      onChangePaginationCallback();
    }
  };

  const onShowSizeChange = (current, size) => {
    setCurrentPage(1);
    setPageLimit(size);
  };

  // const initFunc = () => {
  //   // console.log('initFunc initFunc');
  //   if (page && limit && getActorsAndCount) {
  //     const offset = limit * (page - 1);
  //
  //     const params = {
  //       limit: +limit,
  //       offset,
  //       ...orderRules,
  //     };
  //
  //     if (filterByTags.unbannedActors) {
  //       params.uinfo = {
  //         groups__not: [defaultGroupUUID],
  //       };
  //     }
  //
  //     getActorsAndCount(params);
  //   }
  // };

  // useEffect(() => {
  //   initFunc();
  // }, [
  //   page,
  //   limit,
  //   JSON.stringify(orderRules),
  //   JSON.stringify(filterByTags),
  // ]);

  useEffect(() => {
    if (+page === 1) {
      setNewCurrentPage(1);
    } else {
      setNewCurrentPage(null);
    }
  }, [page]);

  useEffect(() => {
    getOrderCallBack?.(orderRules);
  }, [orderRules]);

  useEffect(() => {
    savePagination({
      pageLimit,
      currentPage,
      offset,
    });
  }, [pageLimit, currentPage]);

  return (
    <UI54Table
      columns={columns}
      dataSource={tableData}
      loading={fetching}
      onRow={onRow}
      rowSelection={rowSelection}
      pagination={{
        total,
        current: currentPage,
        pageSize: pageLimit,
        pageSizeOptions: [25, 50],
        onShowSizeChange,
        onChange: onChangePagination,
      }}
    />

  // <AntTableWithPagination
  //   columns={columns}
  //   data={tableData}
  //   loading={fetching}
  //   onRow={onRow}
  //   rowSelection={rowSelection}
  //   total={total}
  //   newCurrentPage={newCurrentPage}
  //   getPaginationOptions={savePagination}
  //   onChangePaginationCallback={onChangePaginationCallback}
  // />
  );
}

export default ActorsList;

ActorsList.propTypes = {
  actorUUID: PropTypes.string,
  afterDeleteActor: PropTypes.func.isRequired,
  filterByTags: PropTypes.any,
  getActorsAndCount: PropTypes.func,
  onSelectActors: PropTypes.func.isRequired,
  selectActor: PropTypes.func.isRequired,
  selectedActors: PropTypes.any,
  setMasqueradingUser: PropTypes.func.isRequired,
  switchView: PropTypes.func.isRequired,
  tableData: PropTypes.array,
  total: PropTypes.any,
  withCheckBox: PropTypes.bool,
};
