import React from 'react';
import { Link } from 'react-router-dom';
import {
  mdiAccountCircleOutline,
  mdiAccountCogOutline,
  mdiHexagonMultipleOutline,
  mdiHomeOutline,
  mdiMonitorDashboard,
  mdiMonitorScreenshot,
  mdiTextBoxSearchOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { capitalize } from '../../mainUtils';
import VersionLabel from '../VersionLabel';
import { AuthHelper } from '../../auth/utils';
import { getMeIsRootOrAdmin } from '../../profile/selectors';
import useURLParams from '../../hooks/useURLParams';
import './SidebarComponent.scss';
import BaseIcon from '../../_ui/BaseIcon/BaseIcon';

function SidebarComponent() {
  const { t } = useTranslation();
  const { currentLocationPathname } = useURLParams();

  const meIsRootOrAdmin = useSelector(getMeIsRootOrAdmin);

  const menuItems = [
    {
      label: capitalize(t('auth.pages.profile', 'profile')),
      linkTo: '/admin/profile',
      iconPath: mdiAccountCircleOutline,
    },
    {
      label: capitalize(t('auth.pages.start_page', 'start page')),
      linkTo: '/admin/start-page',
      iconPath: mdiHomeOutline,
      onlyAdmin: true,
    },
    // {
    //   label: capitalize(t('auth.pages.biome_info', 'biome info')),
    //   linkTo: '/biome-info',
    //   iconPath: mdiInformationOutline,
    //   onlyAdmin: true
    // },
    {
      label: capitalize(t('auth.pages.actors', 'actors')),
      linkTo: '/admin/actors/list',
      iconPath: mdiAccountCogOutline,
      onlyAdmin: true,
    },
    {
      label: capitalize(t('auth.pages.public_interfaces', 'public interfaces')),
      linkTo: '/admin/public-interfaces',
      iconPath: mdiMonitorScreenshot,
      onlyAdmin: true,
    },
    {
      label: capitalize(t('auth.pages.groups_priority', 'groups priority')),
      linkTo: '/admin/groups-priority',
      iconPath: mdiHexagonMultipleOutline,
      onlyAdmin: true,
    },
    {
      label: capitalize(t('auth.pages.logs', 'logs')),
      linkTo: '/admin/logs',
      iconPath: mdiTextBoxSearchOutline,
      onlyAdmin: true,
    },
    {
      label: capitalize(t('auth.pages.biome_dashboard', 'biome dashboard')),
      linkTo: '/admin/biome-dashboard',
      iconPath: mdiMonitorDashboard,
      onlyAdmin: true,
    },
  ];

  return (
    <aside id="main-sidebar" className="dt-sidebar">
      <div className="dt-sidebar__container">
        {AuthHelper.isAuthorised() && (
          <div className="flex flex-col justify-between mt-6">
            <ul className="menu-items">
              {menuItems.map((item, id) => {
                const isHiddenTab = item.onlyAdmin && (!meIsRootOrAdmin);

                if (isHiddenTab) {
                  return null;
                }

                return (
                  <li key={`${id}_${item.linkTo}`} className="dt-side-nav__item">
                    <VersionLabel version={item.version}>
                      <Link
                        to={item.linkTo}
                        className={`dt-side-nav__link dt-side-nav__link${currentLocationPathname.includes(item.linkTo.split(
                          '?',
                        )[0])
                          ? '--active'
                          : ''}`}
                      >
                        <div className="mr-4">
                          <BaseIcon path={item.iconPath} size={1} />
                        </div>
                        <p className="dt-side-nav__text">
                          {item.label}
                        </p>
                      </Link>
                    </VersionLabel>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </aside>
  );
}

export default SidebarComponent;
