import {
  Col,
  Row,
  Modal,
} from 'antd';
import _, { get, isEqual, uniq } from 'lodash';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import IStickyBox from 'react-sticky-box';

import MasqueradingModal from '../../components/MasqueradingModal';
import ProgressModal from '../../components/ProgressModal';
import ActorCreate from './ActorCreate';
import ActorInfo from './infoForms/ActorInfo/ActorInfo';
import ActorsList from './ActorsList';
import ActorsPageHeader from './ActorsPageHeader';
import SelectedGroupsTable from './SelectedGroupsTable';
import BaseCard from '../../_ui/BaseCard/BaseCard';
import BaseButton from '../../_ui/BaseButton/BaseButton';

import { ApiContext } from '../../api/ApiContextProvider';
import { capitalizeAndTranslateMsg } from '../../mainUtils';
import {
  getAllActors, getDefaultBanGroupUUID, getDefaultGroupNames, isActorsFetching,
} from '../selectors';
import { ActorsConstants } from '../constants/actionTypes';
import useTimeout from '../../hooks/useTimeout';
import useCustomState from '../../hooks/useCustomState';
import useURLParams from '../../hooks/useURLParams';
import { getNameForActor } from '../../54origins/utils54origins';

function ActorsPage() {
  const { reset } = useTimeout(() => {
    getActorsAndCount();
  }, 500);

  const {
    requestGetAllActors,
    requestDeleteActor,
    requestGetMappedActors,
  } = useContext(ApiContext);

  const { getURLParams, setSearchParams } = useURLParams();

  const defaultBANGroupUUID = useSelector(getDefaultBanGroupUUID);
  const allActors = useSelector(getAllActors);
  const actorsFetching = useSelector(isActorsFetching);
  const defaultGroupNames = useSelector(getDefaultGroupNames);

  const {
    tableData,
    searchValue,
    showDeletionProgress,
    deletionProgress,
    changeState,
  } = useCustomState({
    tableData: [],
    searchValue: '',
    showDeletionProgress: false,
    deletionProgress: 0,
  });

  const {
    view,
    type,
    uuid: uuidFromURL,
    actor_type: actorType,
    groups,
    root,
    page,
    limit,
  } = getURLParams();

  const [ignoreSearchCase, setIgnoreSearchCase] = useState(true);
  const [masqueradingUser, setMasqueradingUser] = useState('');

  const [selectedActors, setSelectedActors] = useState(new Set());
  const [orderRules, setOrderRules] = useState({});

  const [actorsCount, changeActorsCount] = useState(0);
  const [requestFilters, changeRequestFilters] = useState({
    actorsFilter: [],
    groupsFilter: [],
    root,
    unbanned: 'excluded',
  });

  // console.log('requestFilters', requestFilters);

  const {
    actorsFilter,
    groupsFilter,
    root: requestFilterRoot,
  } = requestFilters;

  const isPermsView = view === 'permissions';
  const isCreateView = view === 'create';
  const isInfoView = view === 'info';

  const onChangePaginationCallback = () => {
    setSelectedActors(new Set());
  };

  const switchView = (newView = '', uuid = '') => {
    if (uuidFromURL !== uuid || newView !== view) {
      setSearchParams({
        view: newView,
        uuid,
      });
    }
  };

  const formattedTableData = (data) => data.map((item) => {
    const {
      actor_type: type,
      uuid,
      created,
      root_perms_signature: root,
    } = item;

    const getInfo = (path, value = '') => get(item, `uinfo.${path}`, value);

    const actorData = {
      uuid,
      type,
      created,
      root,
      isRobot: getInfo('isRobot', false),
      weight: getInfo('weight', 1),
      comment: getInfo('comment'),
      internal: getInfo('internal_user', false),
      registeredOnServiceUUID: getInfo('registered_on_service_uuid'),
    };

    switch (type) {
      case 'user':
      case 'classic_user':
        return {
          ...actorData,
          name: getNameForActor(item),
          groups: getInfo('groups', []),
        };

      case 'group':
        const name = getNameForActor(item);

        return {
          ...actorData,
          name,
          default: defaultGroupNames.includes(name),
        };

      case 'service':
        return {
          ...actorData,
          name: getNameForActor(item),
          groups: getInfo('groups', []),
        };

      default:
        return item;
    }
  });

  const filterTableData = () => {
    let data = [];

    data = formattedTableData([...allActors]);

    changeState({ tableData: data });
  };

  const handleSelectedFilters = (filters = []) => {
    console.log('filters', filters);

    const newFilters = filters.filter((item) => item.value !== 'addMore');

    filterTableData();

    const filtersToUrl = (filterKey) => newFilters
      .filter(({ key }) => key === filterKey)
      .map(({ value }) => value);

    const actorsFilter = filtersToUrl('actor_type');
    const groupsFilter = filtersToUrl('uinfo.groups');
    const rootFilter = filtersToUrl('is_root');

    // console.log('actorsFilter', actorsFilter);
    // console.log('groupsFilter', groupsFilter);
    // console.log('rootFilter', rootFilter);

    const searchParams = {
      actor_type: actorsFilter,
      groups: groupsFilter,
      root: rootFilter,
      page: 1,
      limit: 25,
      order: 'd-created',
    };

    setSearchParams(searchParams);

    const requestFiltersData = {
      actorsFilter,
      groupsFilter,
      root: _.head(rootFilter),
      unbanned: filters.find((item) => item.value === 'unbanned')?.status,
    };

    // console.log('requestFiltersData', requestFiltersData)

    // setSearchParams({
    //   page: 1,
    //   limit: 25,
    // });

    changeRequestFilters(requestFiltersData);
  };

  const selectActor = (view, uuid) => {
    setSearchParams({
      view,
      uuid,
    });
  };

  const afterDeleteActor = () => {
    setSelectedActors(new Set());

    changeState({
      showDeletionProgress: false,
      deletionProgress: 0,
    });
    switchView();
  };

  const getActorsAndCount = async (params) => {
    // console.log('getActorsAndCount');
    // const {actorsFilter, groupsFilter, root} = requestFilters;

    const filterData = {};

    if (!_.isEmpty(actorsFilter)) {
      if (actorsFilter.includes('user')) {
        filterData.actor_type = ['classic_user', ...actorsFilter];
      } else {
        filterData.actor_type = actorsFilter;
      }
    }

    if (!_.isEmpty(groupsFilter)) {
      filterData.uinfo = {
        groups: groupsFilter,
      };
    }

    if (_.head(requestFilterRoot)) {
      filterData.is_root = true;
    }

    const offset = (page - 1) * limit;

    const data = {
      limit: +limit,
      offset,
      ...params,
      ...filterData,
    };

    // console.log('data data data', data);

    if (searchValue) {
      data.search_data = {
        value: searchValue,
        ignore_case: ignoreSearchCase,
        fields:
            {
              base: ['uuid'],
              uinfo: ['first_name', 'last_name', 'group_name', 'service_name'],
            },
      };
    }

    try {
      const allActorsOnPage = await requestGetAllActors(data);

      const registeredOnService = allActorsOnPage?.actors?.flatMap((item) => {
        const service = item?.uinfo?.registered_on_service_uuid;

        if (service) {
          return [service];
        }
        return [];
      });

      const uniqServicesUUIDs = uniq(registeredOnService);

      if (uniqServicesUUIDs.length !== 0) {
        requestGetMappedActors({
          data: { uuid: uniqServicesUUIDs },
          constants: [
            ActorsConstants.GET_REGISTERED_ON_SERVICES_REQUEST,
            ActorsConstants.GET_REGISTERED_ON_SERVICES_SUCCESS,
            ActorsConstants.GET_REGISTERED_ON_SERVICES_FAILURE,
          ],
        });
      }

      changeActorsCount(_.get(allActorsOnPage, 'total'));
      // console.log('allActorsOnPage', allActorsOnPage)
    } catch (e) {
      console.log('eeeeeeeeeeeeeee', e);
    }
  };

  const afterCreateActor = (actor) => {
    selectActor('info', actor.uuid);

    getActorsAndCount();
  };

  const afterUpdateActor = () => {
    getActorsAndCount();
  };

  const initFromURL = () => {
    const getFilters = (filters, key) => {
      if (_.isArray(filters)) {
        return filters.map((item) => (
          {
            key,
            value: item,
          }
        ));
      } if (filters) {
        return [
          {
            key,
            value: filters,
          },
        ];
      }
      return [];
    };

    const filters = [
      ...getFilters(actorType, 'actor_type'),
      ...getFilters(groups, 'uinfo.groups'),
      ...getFilters(root, 'is_root'),
    ];

    if (uuidFromURL) {
      selectActor(view, uuidFromURL);
    }

    // console.log('initFromURL filters', filters)

    if (filters.length > 0) {
      handleSelectedFilters(filters);
    } else {
      handleSelectedFilters();
    }
  };

  const deleteSomeActors = async (uuids) => {
    changeState({ showDeletionProgress: true });

    for (let index = 0; index < uuids.length; index += 1) {
      await requestDeleteActor(uuids[index]);

      changeState({ deletionProgress: index + 1 });

      if (index + 1 === uuids.length) {
        afterDeleteActor();
      }
    }
  };

  const onDeleteSomeActors = () => {
    Modal.confirm({
      title: capitalizeAndTranslateMsg(
        'auth.buttons.remove_selected_actors',
        'remove selected actors',
      ),
      content: capitalizeAndTranslateMsg(
        'auth.messages.delete_sure_actors',
        'are you sure you want to delete this actors?',
      ),
      maskClosable: true,
      centered: true,
      okText: capitalizeAndTranslateMsg('auth.buttons.remove', 'remove'),
      okType: 'danger',
      okButtonProps: {
        className: 'button-danger',
      },
      cancelText: capitalizeAndTranslateMsg('auth.buttons.cancel', 'cancel'),
      cancelButtonProps: {
        className: 'button-secondary-outlined',
      },
      onOk() {
        deleteSomeActors([...selectedActors]);
      },
    });
  };

  const onSelectActors = (value) => {
    console.log(value);
    if (value === 'all') {
      setSelectedActors((prev) => {
        if (prev.size !== tableData.length) {
          return new Set(tableData.map(({ uuid }) => uuid));
        }
        return new Set();
      });
    } else {
      setSelectedActors((prev) => {
        if (prev.has(value)) {
          prev.delete(value);
        } else {
          prev.add(value);
        }
        return new Set(prev);
      });
    }
  };

  const onChangeSearch = (e) => {
    changeState({ searchValue: e?.target?.value });

    if (actorsCount < 50000) {
      reset();
    }
  };

  const onSearchClick = () => {
    getActorsAndCount();
  };

  const onSuccessUpdateCallBack = () => {
    initActorsFunc();
  };

  const getOrderCallBack = (order) => {
    // console.log('order', order);

    if (!isEqual(order, orderRules)) {
      setOrderRules(order);

      setSearchParams({
        order: `${order.order_by_rule.split('')?.[0]}-${order.order_by_column}`,
      });
    }
  };

  const initActorsFunc = () => {
    // console.log('initFunc initFunc', requestFilters);
    if (page && limit) {
      const offset = limit * (page - 1);

      const params = {
        limit: +limit,
        offset,
        ...orderRules,
      };

      if (requestFilters.unbanned) {
        params.uinfo = {
          ...(requestFilters.unbanned === 'included' ? { groups: [defaultBANGroupUUID] } : {}),
          ...(requestFilters.unbanned === 'excluded' ? { groups__not: [defaultBANGroupUUID] } : {}),
        };
      }

      getActorsAndCount(params);
    }
  };

  useEffect(() => {
    // console.log('EFFECT 111');
    filterTableData();
  }, [
    JSON.stringify(allActors),
    // searchValue
  ]);

  useEffect(() => {
    // console.log('EFFECT 222');
    initFromURL();
  }, []);

  useEffect(() => {
    initActorsFunc();
  }, [
    page,
    limit,
    JSON.stringify(orderRules),
    JSON.stringify(requestFilters),
  ]);

  return (
    <>
      <ProgressModal
        visible={showDeletionProgress}
        all={selectedActors.size}
        current={deletionProgress}
      />
      <MasqueradingModal
        visible={!!masqueradingUser}
        onCancel={() => setMasqueradingUser('')}
        userUUID={masqueradingUser}
      />
      <Row gutter={[24, 16]}>
        {(!isPermsView && view !== 'permsOnService') && (
        <>
          <Col span={16}>
            <BaseCard className="border-blue">
              <ActorsPageHeader
                actorUUID={uuidFromURL}
                ignoreSearchCase={ignoreSearchCase}
                isCreateView={isCreateView}
                onChangeSearch={onChangeSearch}
                onSearchClick={onSearchClick}
                onSelectTagsCallback={handleSelectedFilters}
                setIgnoreSearchCase={setIgnoreSearchCase}
                switchView={switchView}
              />
              <hr className="my-6" />
              {groupsFilter.length !== 0 && (
              <>
                <SelectedGroupsTable
                  selectedGroups={groupsFilter}
                  selectActor={selectActor}
                  selectedActors={selectedActors}
                  actorUUID={uuidFromURL}
                />
                <h4>Filtered users</h4>
              </>
              )}
              {selectedActors.size !== 0 && (
              <>
                <BaseButton
                  className="button-danger-outlined"
                  size="small"
                  disabled={selectedActors.size === 0}
                  onClick={onDeleteSomeActors}
                >
                  {capitalizeAndTranslateMsg('auth.buttons.delete_selected', 'delete selected')}
                </BaseButton>
                <hr className="my-6" />
              </>
              )}
              <ActorsList
                getOrderCallBack={getOrderCallBack}
                onSuccessUpdateCallBack={onSuccessUpdateCallBack}
                actorUUID={uuidFromURL}
                afterDeleteActor={afterDeleteActor}
                fetching={actorsFetching}
                filterByTags={requestFilters}
                getActorsAndCount={getActorsAndCount}
                onSelectActors={onSelectActors}
                selectActor={selectActor}
                selectedActors={selectedActors}
                setMasqueradingUser={setMasqueradingUser}
                switchView={switchView}
                tableData={tableData}
                total={actorsCount}
                onChangePaginationCallback={onChangePaginationCallback}
              />
            </BaseCard>
          </Col>
          {isInfoView && (
          <Col span={8}>
            <IStickyBox
              offsetTop={16}
              offsetBottom={16}
            >
              <ActorInfo
                onSuccessUpdate={afterUpdateActor}
                onSuccessDelete={afterDeleteActor}
                applyFilter={handleSelectedFilters}
              />
            </IStickyBox>
          </Col>
          )}
          {isCreateView && (
          <Col span={8}>
            <ActorCreate
              switchView={switchView}
              onSuccess={afterCreateActor}
            />
          </Col>
          )}
        </>
        )}
      </Row>
    </>
  );
}

export default ActorsPage;
