import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Tag } from 'antd';
import Icon from '@mdi/react';
import {
  mdiCheck, mdiClose, mdiLinkVariant, mdiTrashCanOutline,
} from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { UI54Table } from '@agpl/ui54kit';
import InterfaceIcon54Origins from '../../54origins/components/publicInterface54Origins/InterfaceIcon54Origins';
import AntTable from '../../components/AntTable';
import DeleteButton from '../../components/DeleteButton';

import { capitalize } from '../../mainUtils';
import { isUIFetching } from '../selectors';
import BaseIcon from '../../_ui/BaseIcon/BaseIcon';
import BaseButton from '../../_ui/BaseButton/BaseButton';
import BaseTag from '../../_ui/BaseTag/BaseTag';

function ListOfPublicInterfacesTable({
  tableData,
  selection,
  onRow,
  updateDisplay,
  onSuccessDeleteCallback,
  hiddenElements = [],
}) {
  const { t } = useTranslation();
  const fetchingInterfaces = useSelector(isUIFetching);

  const defaultPaginationOptions = {
    current: 1,
    pageSize: 25,
    pageSizeOptions: [25, 50],
  };

  const [paginationOptions, setPaginationOptions] = useState(
    () => defaultPaginationOptions,
  );
  const { current, pageSize, pageSizeOptions } = paginationOptions || {};

  const dataSource = useMemo(
    () => tableData?.slice((current - 1) * pageSize, current * pageSize),
    [current, pageSize, JSON.stringify(tableData)],
  );

  const onPaginationChange = useCallback((page) => {
    setPaginationOptions((prev) => ({
      ...prev,
      current: page,
    }));
  }, []);

  const onShowSizeChange = useCallback((_, size) => {
    setPaginationOptions((prev) => ({
      ...prev,
      current: 1,
      pageSize: size,
    }));
  }, []);

  const formatIcon = (cell, row) => (
    <InterfaceIcon54Origins
      iconName={_.get(row, 'service_icon')}
      color={_.get(row, 'icon_color')}
      size={1}
    />
  );

  const rowSelection = { selectedRowKeys: selection };

  // const formatName = (cell, row) => (
  //   <Tooltip
  //     title={`interface_${row.id}`}
  //   >
  //     {cell}
  //   </Tooltip>
  // );

  const formatDomain = (cell) => (
    <Link
      to={cell}
      target="_blank"
    >
      <BaseButton
        className="button-primary-link"
        size="small"
        onClick={(e) => e.stopPropagation()}
      >
        <BaseIcon path={mdiLinkVariant} size={1} className="mr-2" />
        <span
          className="inline-block truncate"
          style={{ maxWidth: 300 }}
        >
          {cell}
        </span>
      </BaseButton>
    </Link>
  );

  const formatDisplay = (cell, row) => (
    <Popconfirm
      title={capitalize(t('auth.headers.change_display', 'change display'))}
      onConfirm={(e) => {
        e.stopPropagation();
        updateDisplay?.(row);
      }}
      onCancel={(e) => e.stopPropagation()}
      cancelText={capitalize(t('auth.buttons.cancel', 'cancel'))}
      okText={capitalize(t('auth.buttons.submit', 'submit'))}
      okButtonProps={{ className: 'button-primary' }}
      cancelButtonProps={{ className: 'button-secondary-outlined' }}
    >
      <BaseTag
        className={`tag-icon tag-${cell ? 'green' : 'red'}`}
        onClick={(e) => e.stopPropagation()}
      >
        <BaseIcon path={cell ? mdiCheck : mdiClose} size={1} />
      </BaseTag>
    </Popconfirm>
  );

  const formatDelete = (id) => (
    <DeleteButton
      targetType="public_interface"
      onSuccess={onSuccessDeleteCallback}
      data={{ id }}
    >
      <BaseButton
        className="button-danger-outlined"
        size="small"
      >
        <BaseIcon path={mdiTrashCanOutline} size={1} />
      </BaseButton>
    </DeleteButton>
  );

  const columns = [
    {
      dataIndex: 'service_icon',
      key: 'service_icon',
      className: 'p-2 pl-3',
      render: formatIcon,
    },
    {
      dataIndex: 'service_name',
      key: 'service_name',
      className: 'p-2',
      title: capitalize(t('auth.headers.title', 'title')),
      sorter: (a, b) => _.get(a, 'service_name', '')
        .localeCompare(_.get(b, 'service_name', '')),
    },
    {
      dataIndex: 'service_domain',
      key: 'service_domain',
      className: 'p-2',
      title: capitalize(t('auth.headers.domain', 'domain')),
      render: formatDomain,
    },
    (!hiddenElements.includes('display') ? {
      dataIndex: 'display_service',
      key: 'display_service',
      className: 'p-2',
      title: capitalize(t('auth.headers.display', 'display')),
      sorter: (a, b) => +_.get(a, 'display_service') - +_.get(
        b,
        'display_service',
      ),
      render: formatDisplay,
    } : {}),
    (!hiddenElements.includes('delete') ? {
      dataIndex: 'id',
      key: 'id',
      className: 'p-2  pl-3',
      title: '',
      render: formatDelete,
    } : {}),
  ];

  return (
  // <AntTable
  //   rowKey="id"
  //   data={tableData}
  //   columns={columns}
  //   loading={fetchingInterfaces}
  //   onRow={onRow}
  //   rowSelection={rowSelection}
  // />

    <UI54Table
      rowKey="id"
      dataSource={dataSource}
      columns={columns}
      loading={fetchingInterfaces}
      onRow={onRow}
      rowSelection={rowSelection}
      pagination={{
        total: tableData.length,
        current,
        pageSize,
        pageSizeOptions,
        onShowSizeChange,
        onChange: onPaginationChange,
      }}
    />
  );
}

export default ListOfPublicInterfacesTable;
