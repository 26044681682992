import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Flex, Tag } from 'antd';
import Icon from '@mdi/react';
import { mdiCloseBox } from '@mdi/js';

import { getNameForActor } from '../../54origins/utils54origins';
import BaseIcon from '../../_ui/BaseIcon/BaseIcon';
import BaseTag from '../../_ui/BaseTag/BaseTag';

const styleForContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
};

function ListOfActorsTags({
  actors = [],
  getRemovedUserCallback,
  isModal,
}) {
  const [localActors, setLocalActors] = useState([]);

  const getTagsTemplate = (item, index) => {
    const removeActor = () => getRemovedUserCallback?.(item);

    if (isModal) {
      return (
        <span
          key={index}
          className="mr-4 mt-3"
          style={{
            position: 'relative',
          }}
        >
          <BaseTag
            onClick={removeActor}
            color="blue"
            className="text-sm"
          >
            {getNameForActor(item)}
          </BaseTag>

          <BaseIcon
            uuid={item.uuid}
            onClick={removeActor}
            className="remove-group-icon"
            path={mdiCloseBox}
            size={0.75}
            color="red"
          />
        </span>
      );
    }
    return (
      <BaseTag
        key={index}
        color="blue"
        className="mr-2 mt-3"
      >
        {getNameForActor(item)}
      </BaseTag>
    );
  };

  useEffect(() => {
    setLocalActors(actors);
  }, [JSON.stringify(actors)]);

  return (
    <div>
      <Flex wrap="wrap" className="mb-4">
        {localActors?.map((item, index) => (
          getTagsTemplate(item, index)
        ))}
      </Flex>
    </div>
  );
}

export default ListOfActorsTags;

ListOfActorsTags.propTypes = {
  actors: PropTypes.array,
  getRemovedUserCallback: PropTypes.func,
  isModal: PropTypes.bool,
};
