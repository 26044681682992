import { mdiPlusCircle } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Button,
  Checkbox,
  Col,
  Input, Modal,
  Row,
} from 'antd';
import _, { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TagsSettingList from '../../components/TagsSettingList/TagsSettingList';
import ListOfGroupsAsTagsInModal from './ListOfGroupsAsTagsInModal';

import { capitalize } from '../../mainUtils';
import { getDefaultGroupNames } from '../selectors';
import { ApiContext } from '../../api/ApiContextProvider';
import BaseIcon from '../../_ui/BaseIcon/BaseIcon';
import BaseButton from '../../_ui/BaseButton/BaseButton';

function ActorsPageHeader({
  actorUUID,
  ignoreSearchCase,
  isCreateView,
  onChangeSearch,
  onSearchClick,
  onSelectTagsCallback,
  setIgnoreSearchCase,
  switchView,
}) {
  const { t } = useTranslation();

  const { requestGetAllGroups } = useContext(ApiContext);

  const defaultGroupNames = useSelector(getDefaultGroupNames);

  const showMoreBtn = {
    uuid: 'addMore',
    actor_type: 'group',
    uinfo: { group_name: 'add more...' },
    unselectable: true,
  };

  const [tagsOfGroups, changeTagsOfGroups] = useState([showMoreBtn]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [groupTags, changeGroupTags] = useState([]);
  const [defaultSelectedTags, setDefaultSelectedTags] = useState([{
    key: 'all',
    value: 'all',
  }]);

  const cancelModalFilters = () => {
    setIsModalVisible(false);
  };

  const onSelectTags = (filters) => {
    let changedFilters = [];

    const findFilter = (name) => filters.findIndex((item) => item.value === name) !== -1;

    if (findFilter('addMore')) {
      setIsModalVisible(true);
    }

    if (filters.filter((item) => item.value !== 'all').length === 1 && findFilter('user') && !findFilter('unbanned')) {
      changedFilters = [
        {
          key: 'unban_actors',
          status: 'excluded',
          value: 'unbanned',
        },
        ...filters,
      ].filter((item) => item.value !== 'all');

      setDefaultSelectedTags(changedFilters);
    } else {
      changedFilters = filters.filter((item) => item.value !== 'unbanned');
      setDefaultSelectedTags(changedFilters);
    }

    // console.log('changedFilters', changedFilters)

    onSelectTagsCallback?.(changedFilters);
  };

  const initFunc = async () => {
    const allGroups = await requestGetAllGroups({
      limit: 10,
      offset: 0,
      order_by_column: 'created',
      order_by_rule: 'asc',
    });

    // console.log('INIT groups', get(allGroups, 'actors', []))

    const actorGroupsUnlessBan = get(allGroups, 'actors', []).filter((item) => item?.uinfo?.group_name !== 'BAN');

    changeGroupTags(actorGroupsUnlessBan);
  };

  const filterTags = [
    {
      tags: [
        {
          key: 'all',
          value: 'all',
          label: capitalize(t('auth.headers.all_actors', 'all actors')),
        },
      ],
      isReset: true,
      isRadio: true,
    },
    {
      tags: [
        {
          key: 'unban_actors',
          value: 'unbanned',
          label: capitalize(t('auth.headers.unbanned', 'banned')),
        },
      ],
      isExcluded: true,
    },
    {
      tags: [
        {
          key: 'actor_type',
          value: 'user',
          label: capitalize(t('auth.headers.users', 'users')),
        },
        {
          key: 'actor_type',
          value: 'group',
          label: capitalize(t('auth.headers.groups', 'groups')),
        },
        {
          key: 'actor_type',
          value: 'service',
          label: capitalize(t('auth.headers.services', 'services')),
        },
        {
          key: 'is_root',
          value: 'true',
          label: 'Is ROOT',
          className: 'ml-6 ROOT',
        },
      ],
    },
    {
      tags: [
        ...tagsOfGroups.map((item) => {
          const label = _.get(item, 'uinfo.group_name');

          const propsOfTag = {
            className: defaultGroupNames.includes(label) ? label : '',
          };

          if (item.value && item.label) {
            return item;
          }
          return {
            key: 'uinfo.groups',
            value: item.uuid,
            label,
            unselectable: get(item, 'unselectable', false),
            ...propsOfTag,
          };
        }),
      ],
      isRow: true,
      rowText: capitalize(t('auth.headers.groups', 'groups')),
      className: 'isGroups',
    },
  ];

  useEffect(() => {
    const newMapArr = new Map([...tagsOfGroups, ...groupTags].map((item) => [item.uuid || item.value, item]));

    changeTagsOfGroups([...newMapArr].map(([name, value]) => value));
  }, [groupTags]);

  useEffect(() => {
    initFunc();
  }, []);

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col className="flex items-center">
          <h4 className="header-primary mb-0 mr-4">
            {capitalize(t('auth.pages.actors', 'actors'))}
          </h4>
        </Col>
        <Col
          className="flex items-center"
          span={7}
        >
          <Input.Search
            allowClear
            onSearch={onSearchClick}
            className="w-full"
            onChange={onChangeSearch}
            placeholder={capitalize(t(
              'auth.placeholders.search_actor',
              'enter actor name or UUID',
            ))}
          />
        </Col>
        <Col
          className="flex items-center ml-4"
        >
          <Checkbox
            checked={ignoreSearchCase}
            onChange={(e) => {
              setIgnoreSearchCase(e.target.checked);
            }}
          >
            <h5 className="header-secondary mb-0">
              {capitalize(t('auth.headers.ignore_case', 'ignore case'))}
            </h5>
          </Checkbox>
        </Col>
        <Col
          className="flex ml-auto flex-wrap justify-end"
        >
          {actorUUID && (
            <BaseButton
              className="flex-auto button-warning-outlined mr-2"
              onClick={() => switchView()}
            >
              {capitalize(t('auth.buttons.reset', 'reset'))}
            </BaseButton>
          )}
          <BaseButton
            className={isCreateView ? 'button-warning-outlined' : 'button-primary-outlined'}
            onClick={() => {
              if (isCreateView) {
                switchView();
              } else {
                switchView('create');
              }
            }}
          >
            <BaseIcon
              path={mdiPlusCircle}
              size={1}
              className="slow mr-2"
              rotate={isCreateView ? 765 : 0}
            />
            {isCreateView ? (
              capitalize(t('auth.buttons.cancel', 'cancel'))
            ) : (
              capitalize(t('auth.buttons.create_actor', 'create actor'))
            )}
          </BaseButton>
        </Col>
      </Row>
      <hr className="my-6" />
      <Row>
        <Col>
          <TagsSettingList
            defaultSelectedTags={defaultSelectedTags}
            allTags={filterTags}
            onSelectTags={onSelectTags}
            id="allActors"
            small
          />
        </Col>
      </Row>
      <Modal
        title="Add filters"
        open={isModalVisible}
        destroyOnClose
        footer={[
          <BaseButton key="back" onClick={cancelModalFilters}>
            Cancel
          </BaseButton>,
          <BaseButton
            key="submit"
            type="primary"
            disabled={groupTags.length === 0}
            onClick={cancelModalFilters}
          >
            Add filters
          </BaseButton>,
        ]}
        onCancel={cancelModalFilters}
      >
        {isModalVisible && (
        <ListOfGroupsAsTagsInModal
          getSelectedTags={(tags) => changeGroupTags([...groupTags, ...tags])}
          groupTagsOnActorsPage={groupTags}
        />
        )}
      </Modal>
    </>
  );
}

export default ActorsPageHeader;

ActorsPageHeader.propTypes = {
  actorUUID: PropTypes.string,
  ignoreSearchCase: PropTypes.bool,
  isCreateView: PropTypes.bool,
  onChangeSearch: PropTypes.func.isRequired,
  onSelectTags: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array,
  setIgnoreSearchCase: PropTypes.func.isRequired,
  switchView: PropTypes.func.isRequired,
};
