import React from 'react';
import Icon from '@mdi/react';
import { mdiHelpCircleOutline } from '@mdi/js';
import { Tooltip } from 'antd';
import BaseIcon from '../_ui/BaseIcon/BaseIcon';

function HelpIconWithHover({
  toolTipPlacement = 'left',
  toolTipText = '',
  iconClassName = '',
  iconSize = 1,
}) {
  return (
    <Tooltip
      placement={toolTipPlacement}
      title={toolTipText}
    >
      <BaseIcon
        size={iconSize}
        className={iconClassName}
        path={mdiHelpCircleOutline}
      />
    </Tooltip>
  );
}

export default HelpIconWithHover;
