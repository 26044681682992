import React, {
  useContext, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  isEmpty, last, uniq,
} from 'lodash';
import { Alert, Spin } from 'antd';
import { mdiReload } from '@mdi/js';

import ActorListOfLogsComponent from '../../../logsView/components/ActorListOfLogsComponent';
import TagsSettingList from '../../../components/TagsSettingList/TagsSettingList';
import BtnSendRequestAndShowResult from '../../../components/BtnSendRequestAndShowResult';

import { ApiContext } from '../../../api/ApiContextProvider';
import { getActorUUID } from '../../selectors';
import { getNameForActor } from '../../../54origins/utils54origins';
import { ActorsConstants } from '../../constants/actionTypes';
import { getUnSyncLogActorsDataMap } from '../../reducers/actorsMapReducer';
import { toFlatPropertyMap } from '../../../mainUtils';

import useTimeout from '../../../hooks/useTimeout';

function ActorLogsTableAndFilters() {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { requestGetActorLogs, requestGetMappedActors } = useContext(ApiContext);

  const actorUUID = useSelector(getActorUUID);
  const unSyncLogActorsDataMap = useSelector(getUnSyncLogActorsDataMap);

  const [spinning, setSpinning] = useState(false);
  const [initMount, setInitMount] = useState(false);
  const [listOfTags, setListOfTags] = useState([]);
  const [logsData, setLogsData] = useState([]);
  const [defaultSelectedFilter, setDefaultSelectedFilter] = useState([]);
  const [filteredLogsData, setFilteredLogsData] = useState([]);

  const { reset } = useTimeout(() => setSpinning(false), 500);

  const onSelectTags = async (selectedFilter) => {
    setDefaultSelectedFilter(selectedFilter);

    setSpinning(true);

    if (selectedFilter?.length !== 0) {
      const filtersUUIDs = selectedFilter.map((item) => item.value);
      const filteredServices = logsData.filter((item) => filtersUUIDs.includes(item.service));

      setFilteredLogsData(filteredServices);
    } else {
      setFilteredLogsData([logsData[0]]);
    }

    reset();
  };

  const changeDefaultSelectedFilter = (data) => {
    const { service } = data[0] || {};

    const selectedFilter = data.length === 0 ? [] : [{
      value: service,
      key: service,
      label: getNameForActor(unSyncLogActorsDataMap.get(service)),
    }];

    if (defaultSelectedFilter.length === 0) {
      setDefaultSelectedFilter(selectedFilter);
    }
  };

  const getActorLogs = async () => {
    const receivedLogsData = await requestGetActorLogs(actorUUID);

    const flattenObj = toFlatPropertyMap(receivedLogsData);
    let arrOfActorsUUIDs = [];

    for (const key in flattenObj) {
      const lastKey = last(key.split('.'));

      // check if key is actor uuid
      if (lastKey.length === 36) {
        arrOfActorsUUIDs.push(lastKey);
      }

      if (key.indexOf('groups') !== -1) {
        arrOfActorsUUIDs.push(flattenObj[key]);
      }
    }

    arrOfActorsUUIDs = uniq(arrOfActorsUUIDs);

    const servicesUUIDs = Object.keys(receivedLogsData);

    if (!isEmpty(servicesUUIDs)) {
      requestGetMappedActors({
        data: {
          uuid: servicesUUIDs,
        },
        constants: [
          ActorsConstants.GET_UNSYNC_LOG_ACTOR_REQUEST,
          ActorsConstants.GET_UNSYNC_LOG_ACTOR_SUCCESS,
          ActorsConstants.GET_UNSYNC_LOG_ACTOR_FAILURE,
        ],
      });
    }

    if (!isEmpty(arrOfActorsUUIDs)) {
      requestGetMappedActors({
        data: {
          uuid: arrOfActorsUUIDs,
        },
        constants: [
          ActorsConstants.GET_UNSYNC_ACTORS_REQUEST,
          ActorsConstants.GET_UNSYNC_ACTORS_SUCCESS,
          ActorsConstants.GET_UNSYNC_ACTORS_FAILURE,
        ],
      });
    }

    const formattedLogs = Object.keys(receivedLogsData).map((key) => ({
      service: key,
      logs: receivedLogsData[key],
    }));

    return formattedLogs;
  };

  const getActorLogsAndSetSelectedFilter = async () => {
    const formattedLogs = await getActorLogs();

    const firstSelectedService = formattedLogs[0] ? [formattedLogs[0]] : [];

    changeDefaultSelectedFilter(formattedLogs);
    setLogsData(formattedLogs);
    setFilteredLogsData(firstSelectedService);

    if (formattedLogs.length === 0) {
      setListOfTags([]);
    }
  };

  const getFormattedServicesForTags = () => {
    const arr = [];

    for (const service of unSyncLogActorsDataMap.keys()) {
      const actorInfo = unSyncLogActorsDataMap.get(service);

      arr.push(
        {
          key: service,
          value: actorInfo?.uuid,
          label: getNameForActor(actorInfo),
        },
      );
    }

    return arr;
  };

  const getActorLogsAndSetFilteredForSelectedService = async () => {
    const formattedLogs = await getActorLogs();

    const newDataArr = [...formattedLogs]
      .filter((item) => item?.service === defaultSelectedFilter[0]?.key);

    setFilteredLogsData(newDataArr);
  };

  const syncPackageCallback = async (status, packageId, mode) => {
    if (status === 200) {
      if (mode === 'delete') {
        const newData = [...filteredLogsData];
        newData[0].logs = newData[0]?.logs?.filter((item) => item.package_id !== packageId);

        setFilteredLogsData(newData);

        getActorLogsAndSetFilteredForSelectedService();
      } else {
        const newData = [...filteredLogsData];
        newData[0].logs = newData[0]?.logs?.map((item) => (item.package_id === packageId ? {
          ...item,
          retry: true,
        } : item));
        setFilteredLogsData(newData);
      }
    } else {
      getActorLogsAndSetSelectedFilter();
    }
  };

  const reloadUnSyncChangesCallback = () => {
    setSpinning(true);
    reset();

    return getActorLogsAndSetFilteredForSelectedService();
  };

  const selectedFilterIsEmpty = defaultSelectedFilter.length === 0;

  const initFunc = async () => {
    await setSpinning(true);

    await getActorLogsAndSetSelectedFilter().then(() => {
      setSpinning(false);
    });
  };

  useEffect(() => {
    initFunc();
  }, []);

  useEffect(() => {
    if (!initMount && unSyncLogActorsDataMap.size !== 0) {
      setListOfTags([
        {
          tags: getFormattedServicesForTags(),
          isRadio: true,
        },
      ]);

      setInitMount(true);
    }
  }, [unSyncLogActorsDataMap]);

  useEffect(() => () => {
    dispatch({
      type: ActorsConstants.UNSYNC_LOG_ACTOR_CLEAR,
    });
  }, []);

  return (
    <>
      <Alert
        type="info"
        showIcon
        description={(
          <>
            <div>
              {t('auth.messages.unsync_changes_info_first')}
            </div>
            <div>
              {t('auth.messages.unsync_changes_info_second')}
            </div>
            <div>
              {t('auth.messages.unsync_changes_info_third')}
            </div>
            <div>
              {t('auth.messages.unsync_changes_info_fourth')}
            </div>
          </>
        )}
      />

      {!selectedFilterIsEmpty && (
        <>
          <BtnSendRequestAndShowResult
            action={reloadUnSyncChangesCallback}
            btnLabel="Update all"
            className="mt-2"
            iconSize={1}
            pathForIcon={mdiReload}
          />
          <h4 className="mt-2 mb-2">List of services</h4>
        </>
      )}
      <TagsSettingList
        defaultSelectedTags={defaultSelectedFilter}
        allTags={listOfTags}
        onSelectTags={onSelectTags}
        id="allActors"
      />
      <div className="mt-6" />
      <Spin tip="Loading..." spinning={spinning}>
        <ActorListOfLogsComponent
          listOfLogs={filteredLogsData}
          syncPackageCallback={syncPackageCallback}
          reloadUnSyncChangesCallback={reloadUnSyncChangesCallback}
        />
      </Spin>
    </>
  );
}

export default ActorLogsTableAndFilters;
